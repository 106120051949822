/* eslint-disable no-undef */
import React, { useState } from 'react';
import './assets/scss/App.scss';
import './App.css';
import localStorage from 'local-storage';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/misc/Header';
import Main from './components/Main';
import ThemeToggler from './helpers/themeToggler';
import Footer from './components/misc/Footer';

function App() {
  const [theme, setTheme] = useState(localStorage.get('theme') || 'light');
  if (localStorage.get('theme') === null) {
    localStorage.set('theme', 'light');
  }
  if (theme === 'light') {
    document.body.classList.remove('dark-bg');
    document.body.classList.add('light-bg');
  } else if (theme === 'dark') {
    document.body.classList.remove('light-bg');
    document.body.classList.add('dark-bg');
  }
  return (
    <ThemeToggler.Provider value={[theme, setTheme]}>
      <BrowserRouter>
        <Header theme={theme} ThemeToggler={setTheme} />
        <Main theme={theme} />
        <Footer theme={theme} />
      </BrowserRouter>

    </ThemeToggler.Provider>
  );
}

export default App;
