/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import getter from '../../helpers/getter';
import 'rc-pagination/assets/index.css';
import MapInfoLoaded from './MapInfoLoaded';
import MapInfoLoader from './MapInfoLoader';
import config from '../../config/config';
import MapLoader from './MapLoader';
import MapCompletionsLoaded from './MapCompletionsLoaded';
import MapStagesLoaded from './MapStagesLoaded';
import MapBonusLoaded from './MapBonusLoaded';

const Map = (props) => {
  const { theme } = props;
  const { name } = useParams();
  const { data, loading, error } = getter(`${config.SSL}${config.HOST}/surfstats/map-data/${name}`);
  const {
    info,
    completions,
    stageInfo,
    bonusInfo,
  } = loading ? 'null' : data;
  return (
    <div className={`${theme}`}>
      {
        (() => {
          if (loading) {
            return (
              <>
                <MapInfoLoader theme={theme} />
                <Container>
                  <Row className="mt-5 mb-4">
                    <Col>
                      <Card className="shadow">
                        <MapLoader theme={theme} />
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </>
            );
          } if (!loading && Object.keys(error).length === 0) {
            return (
              <>
                <MapInfoLoaded info={info} theme={theme} />
                <Container>
                  {
                    (() => {
                      if (completions.length > 0) {
                        return (
                          <>
                            <Row className="mt-5 mb-4">
                              <Col>
                                <Card className="shadow">
                                  <MapCompletionsLoaded completions={completions} theme={theme} />
                                </Card>
                              </Col>
                            </Row>
                          </>
                        );
                      }
                      return '';
                    })()
                  }
                  {
                    (() => {
                      if (stageInfo.length > 0) {
                        return (
                          <>
                            <Row className="mt-5 mb-4">
                              <Col>
                                <Card className="shadow">
                                  <MapStagesLoaded stages={stageInfo} theme={theme} />
                                </Card>
                              </Col>
                            </Row>
                          </>
                        );
                      }
                      return '';
                    })()
                  }
                  {
                    (() => {
                      if (bonusInfo.length > 0) {
                        const items = bonusInfo.map((bonuses, i) => (
                          <MapBonusLoaded
                            bonus={i + 1}
                            bonusesData={bonuses}
                            theme={theme}
                            key={i}
                            name={name}
                          />
                        ));
                        return items;
                      }
                      return '';
                    })()
                  }
                </Container>
              </>
            );
          }
          return <span>MAP NOT FOUND</span>;
        })()
      }
    </div>
  );
};

export default Map;
