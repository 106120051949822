/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bonusSort from '../../helpers/bonusSort';

const MapsLoaded = (props) => {
  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const { data } = props;
  const columns = [
    {
      title: 'Map Name',
      field: 'name',
      render: (rowData) => (
        <a className="btn-link" href={`/dashboard/map/${rowData.name}`} alt="">
          {rowData.name}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faLink} />
        </a>
      ),
    },
    {
      title: 'Tier',
      field: 'tier',
      type: 'numeric',
    },
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Bonus',
      field: 'bonus',
      customSort: (a, b) => bonusSort.indexOf(a.bonus) - bonusSort.indexOf(b.bonus),
    },
    {
      title: 'Completions',
      field: 'completions',
    },
  ];

  const tableData = data.map((map) => (
    {
      name: map.map,
      tier: map.tier,
      type: map.type,
      bonus: map.bonus,
      completions: map.completions,
    }
  ));

  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  return (
    <MaterialTable
      title=""
      data={tableData}
      columns={columns}
      options={{
        search: true,
        paging: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
      }}
      style={tableTheme}
      icons={tableIcons}
    />
  );
};
export default MapsLoaded;
