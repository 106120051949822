import React from 'react';
import {
  Container,
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

export default function PlayerLoader({ theme }) {
  const opacityBackground = theme === 'dark' ? 0.15 : 1;
  const opacityForeground = theme === 'dark' ? 0.20 : 1;
  return (
    <Container className={`${theme}`}>
      <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`} style={{ marginBottom: '20px' }}>
        <Row style={{ padding: '0.35em' }}>
          <Col>
            <ContentLoader
              width="100%"
              height="auto"
              viewBox="0 0 340 30"
              backgroundColor="#f5f5f5"
              foregroundColor="#dbdbdb"
              backgroundOpacity={opacityBackground}
              foregroundOpacity={opacityForeground}
            >
              <rect x="9" y="4" rx="3" ry="3" width="320" height="22" />
            </ContentLoader>
          </Col>
        </Row>
      </Card>
      <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
        <Row style={{ padding: '20px 50px' }}>
          <Col>
            <ContentLoader
              height="auto"
              width="100%"
              viewBox="0 0 450 250"
              backgroundColor="#f5f5f5"
              foregroundColor="#dbdbdb"
              backgroundOpacity={opacityBackground}
              foregroundOpacity={opacityForeground}
            >
              <circle cx="75" cy="75" r="60" />
              <rect x="160" y="15" rx="3" ry="3" width="140" height="65" />
              <rect x="310" y="15" rx="3" ry="3" width="140" height="65" />
              <rect x="160" y="90" rx="3" ry="3" width="140" height="65" />
              <rect x="310" y="90" rx="3" ry="3" width="140" height="65" />
              <rect x="160" y="165" rx="3" ry="3" width="140" height="65" />
              <rect x="310" y="165" rx="3" ry="3" width="140" height="65" />
              <rect x="5" y="155" rx="3" ry="3" width="140" height="15" />
              <rect x="5" y="175" rx="3" ry="3" width="140" height="15" />
              <rect x="5" y="195" rx="3" ry="3" width="140" height="15" />
              <rect x="5" y="215" rx="3" ry="3" width="42" height="15" />
              <rect x="55" y="215" rx="3" ry="3" width="42" height="15" />
              <rect x="103" y="215" rx="3" ry="3" width="42" height="15" />
            </ContentLoader>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}
