import React from 'react';
import {
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Country from '../../../helpers/Country';

function WRHoldersLoaded(props) {
  const { data, theme } = props;
  let index = 1;
  const tableData = data.map((player) => (
    <tr key={index}>
      <td>
        {index++}
      </td>
      <td>
        <Country country={player.country} />
      </td>
      <td>
        <a
          className="btn-link"
          href={`player/${player.steamid}`}
          alt=""
          rel="noreferrer"
        >
          {player.name}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      </td>
      <td>{player.wrs}</td>
      <td>{player.finishedbonuses}</td>

    </tr>
  ));

  return (
    <div className="table-responsive">
      <table className={`table align-items-center ${theme === 'light' ? '' : 'table-dark'} table-flush`}>
        <thead className={`${theme === 'light' ? 'thead-light' : 'thead-dark'}`}>
          <tr>
            <th>
              #
            </th>
            <th>
              {' '}
            </th>
            <th>Name</th>
            <th>WRs</th>
            <th>Bonuses</th>

          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>

  );
}

export default WRHoldersLoaded;
