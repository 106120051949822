/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card,
  Row,
  Col,
  CardDeck,
  Container,
} from 'react-bootstrap';
import {
  faMap,
  faBold,
  faUserClock,
  faTrophy,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numbersWithCommas from '../../helpers/numbersWithCommas';

const MapInfoLoaded = (props) => {
  const { info, theme } = props;
  const {
    map_name,
    max_velocity,
    tier,
    stages,
    bonuses,
    type,
    total_completions,
    normal_completions,
    total_bonuses_completions,
    normal_bonuses_completions,
  } = info;
  return (
    <div>
      <div className="map-image shadow">
        <img src={`https://raw.clarityeu.com/map-images/${map_name}.jpg`} className="img-fluid" alt="" />
      </div>
      <Container>
        <CardDeck className="carddeck-map">
          <Card className="shadow">
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faMap} className="align-self-center mb-3" />
                <h5 className="text-muted-normal mb-3">Map Name</h5>
                <span className="text-count">{map_name}</span>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow">
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faUserClock} className="align-self-center mb-3" />
                <h5 className="text-muted-normal mb-3">Completions</h5>
                <span className="text-count">{total_completions}</span>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow">
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faBold} className="align-self-center mb-3" />
                <h5 className="text-muted-normal mb-3">Bonuses</h5>
                <span className="text-count">{bonuses}</span>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow">
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faTrophy} className="align-self-center mb-3" />
                <h5 className="text-muted-normal mb-3">Tier</h5>
                <span className="text-count">{tier}</span>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow">
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faTachometerAlt} className="align-self-center mb-3" />
                <h5 className="text-muted-normal mb-3">Velocity</h5>
                <span className="text-count">{numbersWithCommas(max_velocity)}</span>
              </div>
            </Card.Body>
          </Card>

        </CardDeck>
      </Container>
    </div>

  );
};
export default MapInfoLoaded;
