import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import CardStat from './cardstats/CardStat';
import Records from './recent10records/Records';
import TopPlayers from './top10players/TopPlayers';
import WRHolders from './topwrholders/WRHolders';
import WRBonusHolders from './topwrbonusholders/WRBonusHolders';
import StageWRHolders from './topstagewrholders/StageWRHolders';
import RecentlyAddedMaps from './recentlyaddedmaps/RecentlyAddedMaps';
import themeToggler from '../../helpers/themeToggler';

function Dashboard() {
  const [theme] = React.useContext(themeToggler);
  return (
    <Container className={`${theme} py-5`}>
      <Row>
        <Col>
          <CardStat theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>
          <Records theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>
          <TopPlayers theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>

          <WRHolders theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>
          <WRBonusHolders theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>
          <StageWRHolders theme={theme} />
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col>
          <RecentlyAddedMaps theme={theme} />
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
