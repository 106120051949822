/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import Paypal from './PayPal';
import config from '../../config/config';

function VipAccess(props) {
  const { theme } = props;
  const [steamId, setSteamId] = useState('');
  const [error, setError] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const [steamId32, setSteamId32] = useState(null);

  const handleChange = (event) => setSteamId(event.target.value);

  const handleGet = async () => {
    setIsPaying(false);
    setError(null);
    if (!steamId) {
      setError({ message: 'Please enter your Steam profile ID', variant: 'danger' });
    } else {
      try {
        const steamId32 = (await axios.get(`${config.SSL}${config.HOST}/steam/get`, { params: { playerId: steamId } })).data;
        if (steamId32) {
          const { steamid: steamIdExisting, expiry } = (await axios.get(`${config.SSL}${config.HOST}/vip/check/${steamId32}`)).data;
          console.log(steamIdExisting, steamId32);
          if (steamIdExisting) {
            setError({ message: `You seem to already have VIP access in our servers valid until - ${new Date(expiry).toDateString().replaceAll('/', '-')}`, variant: 'info' });
          } else {
            setSteamId32(steamId32);
            setIsPaying(true);
          }
        } else {
          setError({ message: 'We could not find your steam profile', variant: 'danger' });
        }
      } catch (err) {
        setError({ message: 'We could not find your steam profile', variant: 'danger' });
      }
    }
  };

  const items = (
    <>
      <Col lg={{ span: 12 }}>
        <Card className="mb-5 mb-lg-0 text-center">
          <Card.Header>
            <h5 className="card-title text-uppercase">Get VIP Access</h5>
          </Card.Header>
          <Card.Body>
            <Form.Group controlId="formBasicEmail" className="text-center form-group-center">
              <Form.Label>
                <h6 className="card-title text-muted-normal text-uppercase">Please provide Steam ID or profile URL</h6>
              </Form.Label>
              <Form.Text className="text-muted-normal mt-2">
                Go to the Steam profile you want to get
                <strong style={{ color: theme === 'dark' ? 'white' : 'black' }}> VIP </strong>
                for and copy the profile URL.
              </Form.Text>
              <Form.Text className="text-muted-normal">
                <i>Example: </i>
                <b><i>https://steamcommunity.com/id/ilovesurf</i></b>
              </Form.Text>
              <Form.Control
                type="text"
                placeholder="Paste URL here..."
                value={steamId}
                onChange={handleChange}
                className="mt-3 w-50 text-center"
              />

              <Button
                variant={theme}
                className="mt-3 w-25"
                style={{ fontSize: '0.75rem' }}
                onClick={() => handleGet(steamId)}
              >
                Submit
              </Button>
            </Form.Group>
            {error ? (
              <Alert variant={error.variant}>
                {error.message}
              </Alert>
            ) : ''}
          </Card.Body>
        </Card>
      </Col>
    </>
  );

  return (
    <Container className={`${theme} py-2`}>
      <Row className="pricing">
        <Col lg={{ span: 12 }} className="text-center mb-1 mt-1">
          <div className="d-flex justify-content-center">
            <div style={{ maxWidth: '700px' }}>
              <h1>Your support keeps us going!</h1>
              <p style={{ fontSize: '1.25rem' }} className="text-center mt-3">
                Get 1 month VIP access to all of our servers for 5€
              </p>
            </div>

          </div>
        </Col>
        <Col className="card-title text-uppercase text-center mb-5">
          <h5><strong>What you get:</strong></h5>
          <br />
          <p>Reserved slot</p>
          <p>AFK Kick/Spec Immunity</p>
          {/* <p>Access to !replay</p> */}
          <p>Access to +paint (!paintsize ; !paintcolor)</p>
          <p>Access to !ve</p>
          {/* <p>Bypassing Rank 50 requirement for Top 50 servers</p> */}
          <p>Create your own !title</p>
          <p>Access to !demos</p>
        </Col>
        {items}
      </Row>

      <CSSTransition
        in={isPaying}
        out
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <Paypal
          theme={theme}
          steamId={steamId32}
          setIsPaying={setIsPaying}
          setShowPayment={isPaying}
        />
      </CSSTransition>
    </Container>
  );
}

export default VipAccess;
