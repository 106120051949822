import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../assets/images/clarity_fox.png';

function Footer(props) {
  const { theme } = props;

  return (
    <div className={`footer footer-${theme} mt-5 py-5`}>
      <Container>
        <Row className="justify-content-center text-center">
          <Col>
            <img src={logo} alt="" className="img-fluid" width="150" />
            <p className="mt-5">
              Copyright ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              · All Rights Reserved · Clarity Gaming
            </p>
            <p>Coded & Designed by Emil Ivanov</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
