import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  faLink,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Country from '../../helpers/Country';

const RecentRecordsLoaded = (props) => {
  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const { data } = props;
  const columns = [
    {
      title: '',
      field: 'country',
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <a className="btn-link" href={`/player/${rowData.steamid}`} alt="">
          {rowData.name}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      ),
    },
    {
      title: 'Map',
      field: 'map',
      render: (rowData) => (
        <a className="btn-link" href={`/dashboard/map/${rowData.map}`} alt="">
          {rowData.map}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faLink} />
        </a>
      ),
    },
    {
      title: 'Time',
      field: 'runtime',
    },
    {
      title: 'Date',
      field: 'date',
    },
    {
      title: 'steam id',
      field: 'steamid64',
      hidden: true,
    },
  ];

  const tableData = data.map((record) => (
    {
      country: record.country === 'Unknown' ? '' : <Country country={record.country} />,
      name: record.name,
      map: record.map,
      runtime: record.runtime,
      date: record.date,
      steamid: record.steamid,
    }
  ));

  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  return (

    <MaterialTable
      title=""
      data={tableData}
      columns={columns}
      options={{
        search: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
      }}
      style={tableTheme}
      icons={tableIcons}
    />

  );
};
export default RecentRecordsLoaded;
