import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import getter from '../../helpers/getter';
import 'rc-pagination/assets/index.css';
import MapsLoaded from './MapsLoaded';
import MapsLoader from './MapsLoader';
import config from '../../config/config';

const TopPlayers = (props) => {
  const { theme } = props;
  const { data, loading } = getter(`${config.SSL}${config.HOST}/surfstats/maps`);

  return (
    <Container className={`${theme}`}>
      <Row className="mb-5">
        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          <h1>Clarity Surf Maps</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-rankings">
            {
              loading
                ? <MapsLoader theme={theme} />
                : <MapsLoaded data={data} theme={theme} />
            }
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default TopPlayers;
