import React from 'react';
import {
  Card,
  Button,
} from 'react-bootstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getter from '../../../helpers/getter';
import TopPlayersLoaded from './TopPlayersLoaded';
import TopPlayersLoader from './TopPlayersLoader';
import config from '../../../config/config';

function Records(props) {
  const { data, loading } = getter(`${config.SSL}${config.HOST}/surfstats/top-10-players`);
  const { theme } = props;
  return (
    <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
      {
        loading ? ''
          : (
            <Card.Header className="card-header d-flex justify-content-between mb-0">
              <h3 className="mb-0 align-self-center">Top 10 Players</h3>
              <Button href="/dashboard/top-players" variant={theme}>
                {' '}
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                View more
              </Button>
            </Card.Header>
          )
      }
      {
        loading ? <TopPlayersLoader theme={theme} />
          : <TopPlayersLoaded data={data} theme={theme} />
      }
    </Card>
  );
}

export default Records;
