import React from 'react';
import {
  Container,
  Row,
  // Card,
  Col,
  Button,
} from 'react-bootstrap';
// import fox from '../assets/images/fox.png';

function MainPage(props) {
  const { theme } = props;
  return (
    <Container className={`${theme}`}>
      <Row className="mt-5 justify-content-center text-center">
        <Col lg={{ span: 6 }}>
          <h1>404</h1>
          <p>
            The page you are trying to access does not exist.
          </p>
          <Button href="/" variant={theme}>Go to Main Page</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default MainPage;
