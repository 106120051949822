import React from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

function CardStatLoader(props) {
  const { theme } = props;
  const opacityBackground = theme === 'dark' ? 0.15 : 1;
  const opacityForeground = theme === 'dark' ? 0.20 : 1;
  return (
    <CardDeck>
      <Card className="card-stats shadow">
        <Card.Body className="text-center">
          <ContentLoader
            backgroundOpacity={opacityBackground}
            foregroundOpacity={opacityForeground}
            width={100}
            height={160}
            viewBox="0 0 100 130"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="46" cy="38" r="38" />
            <rect x="34" y="90" rx="5" ry="8" width="25" height="10" />
            <rect x="16" y="120" rx="5" ry="8" width="65" height="10" />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card className="card-stats shadow">
        <Card.Body className="text-center">
          <ContentLoader
            backgroundOpacity={opacityBackground}
            foregroundOpacity={opacityForeground}
            width={100}
            height={160}
            viewBox="0 0 100 130"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="46" cy="38" r="38" />
            <rect x="34" y="90" rx="5" ry="8" width="25" height="10" />
            <rect x="16" y="120" rx="5" ry="8" width="65" height="10" />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card className="card-stats shadow">
        <Card.Body className="text-center">
          <ContentLoader
            backgroundOpacity={opacityBackground}
            foregroundOpacity={opacityForeground}
            width={100}
            height={160}
            viewBox="0 0 100 130"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="46" cy="38" r="38" />
            <rect x="34" y="90" rx="5" ry="8" width="25" height="10" />
            <rect x="16" y="120" rx="5" ry="8" width="65" height="10" />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card className="card-stats shadow">
        <Card.Body className="text-center">
          <ContentLoader
            backgroundOpacity={opacityBackground}
            foregroundOpacity={opacityForeground}
            width={100}
            height={160}
            viewBox="0 0 100 130"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="46" cy="38" r="38" />
            <rect x="34" y="90" rx="5" ry="8" width="25" height="10" />
            <rect x="16" y="120" rx="5" ry="8" width="65" height="10" />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card className="card-stats shadow">
        <Card.Body className="text-center">
          <ContentLoader
            backgroundOpacity={opacityBackground}
            foregroundOpacity={opacityForeground}
            width={100}
            height={160}
            viewBox="0 0 100 130"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="46" cy="38" r="38" />
            <rect x="34" y="90" rx="5" ry="8" width="25" height="10" />
            <rect x="16" y="120" rx="5" ry="8" width="65" height="10" />
          </ContentLoader>
        </Card.Body>
      </Card>
    </CardDeck>

  );
}

export default CardStatLoader;
