/* eslint-disable no-unused-vars */
import React, { forwardRef, useState } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  Row,
  Col,
  Card,
  Accordion,
  Modal,
  Button,
} from 'react-bootstrap';
import {
  faBold,
  faUserClock,
  faTrophy,
  faStopwatch,
  faMap,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Country from '../../helpers/Country';

const MapBonusLoaded = (props) => {
  const { name } = props;
  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const { bonus, bonusesData, theme } = props;
  const completionsForBonus = Object.values(bonusesData)[0];
  const bestCompletionForBonus = completionsForBonus ? completionsForBonus[0] : null;
  const columns = [
    {
      title: '#',
      field: 'rank',
    },
    {
      title: '',
      field: 'country',
    },
    {
      title: 'Name',
      field: 'username',
      render: (rowData) => (
        <a className="btn-link" href={`/player/${rowData.steamid}`} alt="">
          {rowData.username}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      ),
    },
    {
      title: 'Time',
      field: 'time',
    },
  ];

  let index = 1;
  const tableData = completionsForBonus ? completionsForBonus.map((player) => (
    {
      rank: index++,
      country: (!player.country || player.country === 'Unknown') ? '' : <Country country={player.country} />,
      username: player.username,
      time: player.runtime,
      steamid: player.steamid,
    }
  )) : null;

  let pageSize;
  if (bestCompletionForBonus) {
    pageSize = completionsForBonus.length > 10 ? 10 : completionsForBonus.length;
  }
  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showBonusText, setBonusText] = useState('show bonus completions');
  const [showBonus, setShowBonus] = useState(false);
  const handleBonusText = () => {
    setShowBonus(!showBonus);
    if (showBonus) {
      setBonusText('show bonus completions');
    } else {
      setBonusText('hide bonus completions');
    }
  };

  return (
    <Row className="mt-5 mb-4">
      <Col>
        <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
          <Modal.Body>
            <img
              src={`https://raw.clarityeu.com/map-images/${name}_b${bonus}.jpg`}
              alt=""
              className="img-fluid"
            />
          </Modal.Body>
          <Button variant={theme} onClick={handleCloseModal}>
            Close
          </Button>
        </Modal>
        <Accordion>
          <Card className="">
            <Card.Header>
              <Row className="mt-3">
                <Col className="text-center">
                  <FontAwesomeIcon icon={faBold} className="align-self-center mb-3 bonus-icon" />
                  <h6 className="text-muted-normal mb-3">Bonus</h6>
                  <span className="text-count-bonus">{bonus}</span>
                </Col>
                <Col className="text-center">
                  <FontAwesomeIcon icon={faUserClock} className="align-self-center mb-3 bonus-icon" />
                  <h6 className="text-muted-normal mb-3">Completions</h6>
                  <span className="text-count-bonus">{completionsForBonus ? completionsForBonus.length : 0}</span>
                </Col>
                <Col className="text-center">
                  <FontAwesomeIcon icon={faTrophy} className="align-self-center mb-3 bonus-icon" />
                  <h6 className="text-muted-normal mb-3">Record holder</h6>
                  <span className="text-count-bonus">{bestCompletionForBonus ? bestCompletionForBonus.username : 'N/A'}</span>
                </Col>
                <Col className="text-center">
                  <FontAwesomeIcon icon={faStopwatch} className="align-self-center mb-3 bonus-icon" />
                  <h6 className="text-muted-normal mb-3">Time</h6>
                  <span className="text-count-bonus">{bestCompletionForBonus ? bestCompletionForBonus.runtime : 'N/A'}</span>
                </Col>
                <Col className="text-center">
                  <FontAwesomeIcon icon={faMap} className="align-self-center mb-3 bonus-icon" />
                  <h6 className="text-muted-normal mb-3">Preview</h6>
                  <Button variant={theme} onClick={handleShowModal}>
                    preview map
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              {
                completionsForBonus ? (
                  <>
                    <MaterialTable
                      title=""
                      data={tableData}
                      columns={columns}
                      options={{
                        search: true,
                        paging: completionsForBonus.length > pageSize,
                        pageSize,
                        pageSizeOptions: [pageSize, 25, 50],
                      }}
                      style={tableTheme}
                      icons={tableIcons}
                    />
                  </>
                ) : <div />
              }

            </Accordion.Collapse>
            <Accordion.Toggle as={Button} className={`button-${theme} show-more mt-3`} variant="primary" eventKey="0" onClick={handleBonusText}>
              {showBonusText}
            </Accordion.Toggle>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};
export default MapBonusLoaded;
