/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

function MapLoader(props) {
  const { theme } = props;
  const spinnerColor = theme === 'dark' ? '#fff' : '#121212';

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-center mt-5">
            <Loader
              type="ThreeDots"
              color={spinnerColor}
              height={100}
              width={100}
              timeout={3000}
            />
          </div>
        </Col>
      </Row>
    </Container>

  );
}
export default MapLoader;
