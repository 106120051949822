import React from 'react';
import {
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RecentlyAddedMapsLoaded(props) {
  const { data, theme } = props;
  let index = 1;
  const tableData = data.map((map) => (
    <tr key={index++}>
      <td>
        <a className="btn-link" href={`/dashboard/map/${map.map}`} alt="">
          {map.map}
          <FontAwesomeIcon className="ml-2" icon={faLink} />
        </a>
      </td>
      <td>{map.tier}</td>
      <td>{map.type}</td>
      <td>{map.bonus}</td>
      <td>
        {
          map.added === 0 ? 'Today' : (map.added === 1 ? `${map.added} day ago` : `${map.added} days ago`)
        }
      </td>
    </tr>
  ));

  return (
    <div className="table-responsive">
      <table className={`table align-items-center ${theme === 'light' ? '' : 'table-dark'} table-flush`}>
        <thead className={`${theme === 'light' ? 'thead-light' : 'thead-dark'}`}>
          <tr>
            <th>
              Map
            </th>
            <th>
              Tier
            </th>
            <th>Type</th>
            <th>Bonus</th>
            <th>Added</th>

          </tr>
        </thead>
        <tbody>
          { tableData }
        </tbody>
      </table>
    </div>

  );
}

export default RecentlyAddedMapsLoaded;
