import React from 'react';
import {
  faLink,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Country from '../../../helpers/Country';

function RecordsLoaded(props) {
  const { data, theme } = props;
  let index = 1;
  const tableData = data.map((record) => (
    <tr key={index++}>
      <td>
        <Country country={record.country} />
      </td>
      <td>
        <a
          className="btn-link"
          href={`player/${record.steamid}`}
          alt=""
          rel="noreferrer"
        >
          {record.name}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      </td>
      <td>
        <a className="btn-link" href={`/dashboard/map/${record.map}`} alt="">
          {record.map}
          <FontAwesomeIcon className="ml-2" icon={faLink} />
        </a>
      </td>
      <td>{record.runtime}</td>
      <td>{record.date}</td>
    </tr>
  ));

  return (
    <div className="table-responsive">
      <table className={`table align-items-center ${theme === 'light' ? '' : 'table-dark'} table-flush`}>
        <thead className={`${theme === 'light' ? 'thead-light' : 'thead-dark'}`}>
          <tr>
            <th>
              {' '}
            </th>
            <th>Name</th>
            <th>Map</th>
            <th>Time</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>

  );
}

export default RecordsLoaded;
