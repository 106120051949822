import React from 'react';
import countries from './countries.json';

const getCountryShort = (country) => {
  try {
    if (country === 'Unknown') {
      return null;
    }
    if (country === 'Czechia') {
      return 'cz';
    }

    if (country === 'Russia') {
      return 'ru';
    }

    if (country === 'North Macedonia') {
      return 'mk';
    }

    if (country === 'The Republic of Lithuania') {
      return 'lt';
    }

    return Object.keys(countries)
      .find((value) => countries[value] === country)
      .toLowerCase();
  } catch (err) {
    console.log(country);
    return null;
  }
};

const Country = (props) => {
  const { country } = props;
  const { size } = props;

  const countryShort = getCountryShort(country);

  if (countryShort) {
    const SIZE = size ? `w${size}` : 'w40';
    const FLAG_SRC = `https://flagcdn.com/${SIZE}/${countryShort}.png`;
    return (
      <img src={FLAG_SRC} className="" alt={`flag-${countryShort}`} />
    );
  }

  console.log(country);
  return null;
};

export default Country;
