// *https://www.registers.service.gov.uk/registers/country/use-the-api*
/* eslint-disable */
import axios from 'axios';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../config/config';
import Country from '../../helpers/Country';
import { useHistory } from "react-router-dom";


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const onChange = (event, history) => {
  const { option, value } = event;
  if (value) {
    const { steamId } = value;
    history.push(`/player/${steamId}`);
  }
}


export default function SearchPlayer(props) {
  const { theme } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const history = useHistory();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get(`${config.SSL}${config.HOST}/search/player`);
      await sleep(500); // For demo purposes.

      const players = response.data;

      if (active) {
        setOptions(players);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div className={`${theme}`}>
      <Autocomplete
        id="asynchronous-demo"
        style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(option, value) => onChange({ option, value }, history)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderOption={(option) => (
          <React.Fragment>
            <span className="mr-3">
              {option.country && <Country country={option.country} className="mr-3" />}
            </span>

            {option.name}

          </React.Fragment>
        )}

        renderInput={(params) => (
          <TextField
            {...params}
            label="Search player"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>

  );
}