const sortOrder = [
  'a few seconds ago',
  'a minute ago',
  '2 minutes ago',
  '3 minutes ago',
  '4 minutes ago',
  '5 minutes ago',
  '6 minutes ago',
  '7 minutes ago',
  '8 minutes ago',
  '9 minutes ago',
  '10 minutes ago',
  '11 minutes ago',
  '12 minutes ago',
  '13 minutes ago',
  '14 minutes ago',
  '15 minutes ago',
  '16 minutes ago',
  '17 minutes ago',
  '18 minutes ago',
  '19 minutes ago',
  '20 minutes ago',
  '21 minutes ago',
  '22 minutes ago',
  '23 minutes ago',
  '24 minutes ago',
  '25 minutes ago',
  '26 minutes ago',
  '27 minutes ago',
  '28 minutes ago',
  '29 minutes ago',
  '30 minutes ago',
  '31 minutes ago',
  '32 minutes ago',
  '33 minutes ago',
  '34 minutes ago',
  '35 minutes ago',
  '36 minutes ago',
  '37 minutes ago',
  '38 minutes ago',
  '39 minutes ago',
  '40 minutes ago',
  '41 minutes ago',
  '42 minutes ago',
  '43 minutes ago',
  '44 minutes ago',
  'an hour ago',
  '2 hours ago',
  '3 hours ago',
  '4 hours ago',
  '5 hours ago',
  '6 hours ago',
  '7 hours ago',
  '8 hours ago',
  '9 hours ago',
  '10 hours ago',
  '11 hours ago',
  '12 hours ago',
  '13 hours ago',
  '14 hours ago',
  '15 hours ago',
  '16 hours ago',
  '17 hours ago',
  '18 hours ago',
  '19 hours ago',
  '20 hours ago',
  '21 hours ago',
  'a day ago',
  '2 days ago',
  '3 days ago',
  '4 days ago',
  '5 days ago',
  '6 days ago',
  '7 days ago',
  '8 days ago',
  '9 days ago',
  '10 days ago',
  '11 days ago',
  '12 days ago',
  '13 days ago',
  '14 days ago',
  '15 days ago',
  '16 days ago',
  '17 days ago',
  '18 days ago',
  '19 days ago',
  '20 days ago',
  '21 days ago',
  '22 days ago',
  '23 days ago',
  '24 days ago',
  '25 days ago',
  'a month ago',
  '2 months ago',
  '3 months ago',
  '4 months ago',
  '5 months ago',
  '6 months ago',
  '7 months ago',
  '8 months ago',
  '9 months ago',
  '10 months ago',
  'a year ago',
  '2 years ago',
  '3 years ago',
  '4 years ago',
  '5 years ago',
  '6 years ago',
  '7 years ago',
  '8 years ago',
  '9 years ago',
  '10 years ago',
  '11 years ago',
  '12 years ago',
  '13 years ago',
  '14 years ago',
  '15 years ago',
  '16 years ago',
  '17 years ago',
  '18 years ago',
  '19 years ago',
  '20 years ago',
];

export default sortOrder;
