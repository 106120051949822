import React, { useEffect } from 'react';

import {
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import Dashboard from './dashboard/Dashboard';
import MainPage from './MainPage';
import TopPlayers from './topplayers/TopPlayers';
import MostActive from './mostactive/MostActive';
import RecentRecords from './recentrecords/RecentRecords';
import Maps from './maps/Maps';
import Map from './map/Map';
import NotFound from './NotFound';
import Player from './player/Player';
import PrivateAccess from './private/PrivateAccess';
import VipAccess from './vip/VipAccess';

ReactGA.initialize('UA-160004257-2');

function Main(props) {
  const { theme } = props;

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <>
      <Switch>
        <Route exact path="/" render={(props) => <Dashboard {...props} theme={theme} />} />
        <Route exact path="/dashboard/top-players" render={(props) => <TopPlayers {...props} theme={theme} />} />
        <Route exact path="/dashboard/most-active" render={(props) => <MostActive {...props} theme={theme} />} />
        <Route exact path="/dashboard/recent-records" render={(props) => <RecentRecords {...props} theme={theme} />} />
        <Route exact path="/dashboard/maps" render={(props) => <Maps {...props} theme={theme} />} />
        <Route exact path="/dashboard/map/:name" render={(props) => <Map {...props} theme={theme} />} />
        <Route exact path="/private" render={(props) => <PrivateAccess {...props} theme={theme} />} />
        <Route exact path="/vip" render={(props) => <VipAccess {...props} theme={theme} />} />
        <Route exact path="/player/:steamId" render={(props) => <Player {...props} theme={theme} />} />
        <Route path="/surf-dashboard" component={MainPage} />
        <Route render={(props) => <NotFound {...props} theme={theme} />} />
      </Switch>
    </>
  );
}

export default Main;
