import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Country from '../../helpers/Country';

const TopPlayersLoaded = (props) => {
  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const { data } = props;
  const columns = [
    {
      title: '#',
      field: 'rank',
    },
    {
      title: '',
      field: 'country',
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <a className="btn-link" href={`/player/${rowData.steamid}`} alt="">
          {rowData.name}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      ),
    },
    {
      title: 'Points',
      field: 'points',
    },
    {
      title: 'Maps',
      field: 'maps',
    },
    {
      title: 'Bonuses',
      field: 'bonuses',
    },
    {
      title: 'Stages',
      field: 'stages',
    },
    {
      title: 'steam id',
      field: 'steamid64',
      hidden: true,
    },
  ];

  let index = 1;
  const tableData = data.map((player) => (
    {
      rank: index++,
      country: player.country === 'Unknown' ? '' : <Country country={player.country} />,
      name: player.name,
      points: player.points,
      maps: player.finishedmaps,
      bonuses: player.finishedbonuses,
      stages: player.finishedstages,
      steamid: player.steamid,
    }
  ));

  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  return (

    <MaterialTable
      title=""
      data={tableData}
      columns={columns}
      options={{
        search: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
      }}
      style={tableTheme}
      icons={tableIcons}
    />

  );
};
export default TopPlayersLoaded;
