import React from 'react';
import {
  Card,
  Button,
} from 'react-bootstrap';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getter from '../../../helpers/getter';
import RecordsLoaded from './RecordsLoaded';
import RecordsLoader from './RecordsLoader';
import config from '../../../config/config';

function Records(props) {
  const { data, loading } = getter(`${config.SSL}${config.HOST}/surfstats/recent-10-records`);
  const { theme } = props;

  return (
    <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
      {
        loading ? ''
          : (
            <Card.Header className="card-header d-flex justify-content-between mb-0">
              <h3 className="mb-0 align-self-center">Recent 10 Map Records</h3>
              <Button href="/dashboard/recent-records" variant={theme}>
                {' '}
                <FontAwesomeIcon icon={faStopwatch} className="mr-2" />
                View more
              </Button>
            </Card.Header>
          )
      }
      {
        loading ? <RecordsLoader theme={theme} /> : <RecordsLoaded data={data} theme={theme} />
      }
    </Card>
  );
}

export default Records;
