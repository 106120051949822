import React from 'react';
import { Card } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

function MapLoader(props) {
  const { theme } = props;
  const opacityBackground = theme === 'dark' ? 0.15 : 1;
  const opacityForeground = theme === 'dark' ? 0.20 : 1;
  return (
    <Card>
      <div className="d-flex justify-content-center">
        <ContentLoader
          width={1200}
          height={500}
          backgroundOpacity={opacityBackground}
          foregroundOpacity={opacityForeground}
          viewBox="0 0 1200 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="text-center d-flex justify-content-center"
        >
          <rect x="27" y="129" rx="4" ry="4" width="20" height="20" />
          <rect x="67" y="130" rx="10" ry="10" width="85" height="19" />
          <rect x="188" y="131" rx="10" ry="10" width="169" height="19" />
          <rect x="402" y="130" rx="10" ry="10" width="85" height="19" />
          <rect x="523" y="131" rx="10" ry="10" width="169" height="19" />
          <rect x="731" y="129" rx="10" ry="10" width="85" height="19" />
          <rect x="852" y="128" rx="10" ry="10" width="85" height="19" />

          <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />

          <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />

          <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />

          <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
          <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />

          <rect x="978" y="128" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
          <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />

          <circle cx="37" cy="57" r="11" />
          <circle cx="77" cy="56" r="11" />
        </ContentLoader>
      </div>
    </Card>

  );
}
export default MapLoader;
