import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import Country from '../../helpers/Country';

const MapStagesLoaded = (props) => {
  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const { stages } = props;
  const pageSize = stages.length > 10 ? 10 : stages.length;

  const columns = [
    {
      title: 'Stage',
      field: 'stage',
    },
    {
      title: '',
      field: 'country',
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <a className="btn-link" href={`/player/${rowData.steamid}`} alt="">
          {rowData.name}
          {' '}
          <FontAwesomeIcon className="ml-2" icon={faUserCircle} />
        </a>
      ),
    },
    {
      title: 'Time',
      field: 'time',
    },
    {
      title: 'Stage Completions',
      field: 'completions',
    },
  ];
  const tableData = stages.map((player) => (
    {
      stage: player.stage,
      country: (!player.country || player.country === 'Unknown') ? '' : <Country country={player.country} />,
      name: player.top_time_username,
      time: player.runtime,
      completions: player.stage_completions,
      steamid: player.steamid,
    }
  ));

  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  return (

    <MaterialTable
      title="Stage Completions"
      data={tableData}
      columns={columns}
      options={{
        search: true,
        paging: stages.length > pageSize,
        pageSize,
        pageSizeOptions: [pageSize, 25, 50],
      }}
      style={tableTheme}
      icons={tableIcons}
    />

  );
};
export default MapStagesLoaded;
