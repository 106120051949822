/* eslint-disable */
import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useParams } from 'react-router-dom';
import getter from '../../helpers/getter';
import config from '../../config/config';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSteam
} from '@fortawesome/free-brands-svg-icons';
import {
  faMap,
  faBold,
  faRoute,
  faCrown,
  faChessRook,
  faChessBishop
} from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  ProgressBar,
  CardDeck,
  Breadcrumb
} from 'react-bootstrap';
import Country from '../../helpers/Country';
import PlayerLoader from './PlayerLoader';


const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Player = (props) => {
  const { steamId } = useParams();
  const { theme } = props;

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  const tableTheme = {
    boxShadow: 'none',
    backgroundColor: 'unset',
  };

  const columnsMapCompletions = [
    {
      title: 'Map Name',
      field: 'mapName',
      render: rowData => (
        <a href={`/dashboard/map/${rowData.mapName}`} className="btn-link">{rowData.mapName}</a>
      ),
    },
    {
      title: 'Tier',
      field: 'tier',
    },
    {
      title: 'Ranked',
      field: 'ranked',
    },
    {
      title: 'Time',
      field: 'runTime',
    },
  ];

  const columnsBonusCompletions = [
    {
      title: 'Map Name',
      field: 'mapName',
      render: rowData => (
        <a href={`/dashboard/map/${rowData.mapName}`} className="btn-link">{rowData.mapName}</a>
      ),
    },
    {
      title: 'Tier',
      field: 'tier',
    },
    {
      title: 'Bonus',
      field: 'bonus',
    },
    {
      title: 'Bonus Rank',
      field: 'ranked',
    },
    {
      title: 'Time',
      field: 'runTime',
    },
  ];

  const columnsStageCompletions = [
    {
      title: 'Map Name',
      field: 'mapName',
      render: rowData => (
        <a href={`/dashboard/map/${rowData.mapName}`} className="btn-link">{rowData.mapName}</a>
      ),
    },
    {
      title: 'Tier',
      field: 'tier',
    },
    {
      title: 'Stage',
      field: 'stage',
    },
    {
      title: 'Stage Rank',
      field: 'ranked',
    },
    {
      title: 'Time',
      field: 'runTime',
    },
  ];

  const Title = styled.span`
    font-family: 'Poppins', sans-serif;
    color: ${theme === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)'};
    font-size: 18px;
    font-weight: 500;
    ${props => props.bold ? 'font-weight: 700;' : ''}
    ${props => props.muted ? 'opacity: .7;' : ''}
  `;

  const Stat = styled.span`
    font-family: 'Open Sans', sans-serif;
    color: ${theme === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)'};
    ${props => props.success ? 'color: #28a745;' : ''}
    ${props => props.warning ? 'color: #ffc107;' : ''}
    ${props => props.info ? 'color:  #17a2b8;' : ''}
    ${props => props.bold ? 'font-weight: 700;' : ''}
    ${props => props.muted ? 'opacity: .7;' : ''}
    font-size: 16px;
  `;

  const { data, loading, error } = getter(`${config.SSL}${config.HOST}/player/${steamId}`);

  const {
    totals,
    basicPlayerInfo,
    isPlayerVip,
    isPlayerPrivate,
    playerCompletionProgress,
    playerCompletions,
    playerFinishedMaps,
    playerFinishedBonuses,
    playerFinishedStages,
  } = data;
  const result = getter(`${config.SSL}${config.HOST}/surfstats/playersonline`);
  const onlineplayers = result.data;

  let mapCompletions;

  if (playerCompletions) {
    mapCompletions = playerCompletions.map(completion => {
      return (
        <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-4 px-3`} key={completion.tier}>
          <Row className="flex-column justify-content-center align-self-center text-center">
            <Title bold>Tier {completion.tier} Completions</Title>
            <Stat bold className="mt-2"><FontAwesomeIcon icon={faMap} /> Maps</Stat>
            <Stat>{completion.mapCompletions} / {completion.mapsTotal} </Stat>
            <Stat muted>({Math.round((completion.mapCompletions / completion.mapsTotal) * 100)}%)</Stat>
            <Stat bold className="mt-2"><FontAwesomeIcon icon={faBold} /> Bonuses</Stat>
            <Stat>{completion.bonusCompletions} / {completion.totalBonuses} </Stat>
            <Stat muted>({Math.round((completion.bonusCompletions / completion.totalBonuses) * 100)}%)</Stat>
            <Stat bold className="mt-2"><FontAwesomeIcon icon={faRoute} /> Stages</Stat>
            <Stat>{completion.stageCompletions} / {completion.totalStages} </Stat>
            <Stat muted>({Math.round((completion.stageCompletions / completion.totalStages) * 100)}%)</Stat>
          </Row>
        </Card>
      );
    });
  }
  return !loading ? (
    <Container className={`${theme}`} >
    <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
      <Row>
        <Col className="alin-self-center">
          <Breadcrumb style={{ margin: '0', height: '100%', paddingTop: '15px' }}>
            <Breadcrumb.Item href="/dashboard/top-players">
              <Title muted>
                Clarity Player Profile
              </Title>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Title bold>
                {basicPlayerInfo && basicPlayerInfo.name ? (
                  <>
                    {basicPlayerInfo.name}
                    {Array.isArray(onlineplayers) && onlineplayers.some(obj => obj.steamid64.includes(basicPlayerInfo.steamid64)) ? (
                      <>
                        {onlineplayers
                          .filter(obj => obj.steamid64.includes(basicPlayerInfo.steamid64))
                          .map(obj => (
                            <span key={obj.id} style={{ marginLeft: '15px', fontSize: '15px' }}>
                              <a href={`steam://connect/${obj.server}`} title='Connect'>
                                <Badge variant='success' >IN-GAME</Badge>
                              </a>
                            </span>
                          ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </Title>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </Card>
    <Row>
      {basicPlayerInfo && <Col lg={{ span: 4 }} className="">
        <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-4 mt-4`}>
          <Row className="justify-content-center">
            <img className="img-fluid rounded-circle" src={basicPlayerInfo.avatarfull} />
          </Row>
          <Row className="justify-content-center mt-3">
            <Title bold style={{ fontSize: '26px', color: isPlayerVip.tier.includes("Head") ? 'orangered' : isPlayerVip.tier.includes("Owner") ? 'goldenrod' : isPlayerVip.tier.includes("Admin") ? 'cornflowerblue' : '' }}>{basicPlayerInfo.name}</Title>
          </Row>
          <Row className="justify-content-center mt-3">
            {isPlayerVip.tier.includes("Owner") || isPlayerVip.tier.includes("Admin") ? (
              <>
                <Col className="text-center" xs={6}>
                  <h1 style={{ marginTop: '0', marginBottom: '0', fontSize: '28px' }}>
                    <a href={`https://steamcommunity.com/profiles/${basicPlayerInfo.steamid64}`} style={{ color: 'unset' }} referrerPolicy="no-referrer" target="_blank">
                      <FontAwesomeIcon icon={faSteam} />
                    </a>
                  </h1>
                </Col>
                <Col className="text-center" xs={6}>
                  <h1 style={{ marginTop: '0', marginBottom: '0', fontSize: '28px', color: isPlayerVip.tier.includes("Head") ? 'orangered' : isPlayerVip.tier.includes("Owner") ? 'goldenrod' : isPlayerVip.tier.includes("Admin") ? 'cornflowerblue' : '' }}>
                    {isPlayerVip.tier.includes("Head") ? (
                      <FontAwesomeIcon icon={faChessRook} title={isPlayerVip.tier} />
                    ) : isPlayerVip.tier.includes("Admin") ? (
                      <FontAwesomeIcon icon={faChessBishop} title={isPlayerVip.tier} />
                    ) : (
                      <FontAwesomeIcon icon={faCrown} title={isPlayerVip.tier} />
                    )}
                  </h1>
                </Col>
              </>
            ) : (
              <Col className="text-center">
                <h1 style={{ marginTop: '0', marginBottom: '0', fontSize: '28px' }}>
                  <a href={`https://steamcommunity.com/profiles/${basicPlayerInfo.steamid64}`} style={{ color: 'unset' }} referrerPolicy="no-referrer" target="_blank">
                    <FontAwesomeIcon icon={faSteam} />
                  </a>
                </h1>
              </Col>
            )}
          </Row>
        </Card>
        <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-3 mt-4`}>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>Rank</h2>
              </Col>
              <Col className="align-self-center text-center">
                <Badge variant="success">{basicPlayerInfo.playerRank < 100 ? 'TOP' : ''} {basicPlayerInfo.playerRank}</Badge>
              </Col>
            </Row>
          </Container>
          <hr className="line"></hr>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>VIP</h2>
              </Col>
              <Col className="align-self-center text-center">
                <Badge
                  variant={isPlayerVip.tier === 'None' || isPlayerVip.expiry === null ? 'secondary' : 'warning'}
                  title={isPlayerVip.expiry === 'None' || isPlayerVip.expiry === null ? undefined : isPlayerVip.expiry.split('T')[0]}
                >
                  {isPlayerVip.tier === 'None' ? 'N/A' : 'Active'}
                </Badge>
              </Col>
            </Row>
          </Container>
          <hr className="line"></hr>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>Private</h2>
              </Col>
              <Col className="align-self-center text-center">
                <Badge
                  variant={isPlayerPrivate.publicprivate != 'None' || isPlayerPrivate.expiry != 'None' ? 'primary' : 'secondary'}
                  title={isPlayerPrivate.expiry !== 'None' ? isPlayerPrivate.expiry.split('T')[0] : (isPlayerPrivate.publicprivate !== null ? isPlayerPrivate.publicprivate.split('T')[0] : '')}
                >
                  {isPlayerPrivate.publicprivate != 'None' || isPlayerPrivate.expiry != 'None' ? 'Active' : 'N/A'}
                </Badge>
              </Col>
            </Row>
          </Container>
          <hr className="line"></hr>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>Country</h2>
              </Col>
              <Col className="align-self-center text-center">
                <Country country={basicPlayerInfo.country} size="40" />
              </Col>
            </Row>
          </Container>
          <hr className="line"></hr>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>Last seen</h2>
              </Col>
              <Col className="align-self-center  text-center">
                <h5 style={{ fontSize: '16px', marginBottom: '0', fontWeight: '700' }}> {basicPlayerInfo.lastSeenDays}</h5>
                <h5 style={{ fontSize: '12px', marginBottom: '0', opacity: '.7' }}> {basicPlayerInfo.lastSeenDate} {basicPlayerInfo.lastSeenTime}</h5>
              </Col>
            </Row>
          </Container>
          <hr className="line"></hr>
          <Container>
            <Row className="justify-content-between px-2">
              <Col className="align-self-center">
                <h2 style={{ fontSize: '18px', marginBottom: "0" }}>Joined</h2>
              </Col>
              <Col className="align-self-center  text-center">
                <h5 style={{ fontSize: '16px', marginBottom: '0', fontWeight: '700' }}> {basicPlayerInfo.joinedDays}</h5>
                <h5 style={{ fontSize: '12px', marginBottom: '0', opacity: '.7' }}> {basicPlayerInfo.joinedDate} {basicPlayerInfo.joinedTime}</h5>
              </Col>
            </Row>
          </Container>
        </Card>
      </Col>
      }

      <Col lg={{ span: 8 }}>
        {totals && playerCompletionProgress && <Row >
          <Col lg={{ span: 6 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-3 mt-4`}>

              <Container>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Overall Rank</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.playerRank)}</Stat> <Stat> / {numberWithCommas(totals.totalPlayers)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Total Playtime</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.totalPlaytime)} hrs</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Surfing Time</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.surfingTime)} hrs</Stat>
                    <Stat muted> ({Math.round((playerCompletionProgress.surfingTime / playerCompletionProgress.totalPlaytime) * 100)}%)</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map Completions</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.finishedMaps)}</Stat>
                    <Stat> / {totals.totalMaps}</Stat>
                    <Stat muted> ({Math.round((playerCompletionProgress.finishedMaps / totals.totalMaps) * 100)}%)</Stat>

                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Bonus Completions</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.finishedBonuses)}</Stat>
                    <Stat> / {totals.totalBonuses}</Stat>
                    <Stat muted> ({Math.round((playerCompletionProgress.finishedBonuses / totals.totalBonuses) * 100)}%)</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Stage Completions</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.finishedStages)}</Stat>
                    <Stat> / {totals.totalStages}</Stat>
                    <Stat muted> ({Math.round((playerCompletionProgress.finishedStages / totals.totalStages) * 100)}%)</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map Top 10s</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.topTens)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Bonus WR Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.bonusWrPoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map Top 10s Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.topTensPoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.mapPoints)}</Stat>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
          <Col lg={{ span: 6 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-3 mt-4`}>
              <Container>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Total Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat bold>{numberWithCommas(playerCompletionProgress.totalPoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Total Connections</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.totalConnections)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Spectate Time</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.spectateTime)} hrs</Stat>
                    <Stat muted> ({Math.round((playerCompletionProgress.spectateTime / playerCompletionProgress.totalPlaytime) * 100)}%)</Stat>

                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map Records</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat success bold>{numberWithCommas(playerCompletionProgress.mapRecords)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Bonus Records</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat info bold>{numberWithCommas(playerCompletionProgress.bonusRecords)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Stage Records</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat warning bold>{numberWithCommas(playerCompletionProgress.stageRecords)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Map WR Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.mapWrPoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Stage WR Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.mapWrStagePoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Group Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.groupPoints)}</Stat>
                  </Col>
                </Row>
                <Row className="justify-content-between px-2 py-1">
                  <Col className="align-self-center">
                    <Title>Bonus Points</Title>
                  </Col>
                  <Col className="align-self-center text-center">
                    <Stat>{numberWithCommas(playerCompletionProgress.mapWrBonusPoints)}</Stat>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
        }
        {totals && playerCompletionProgress && <Row className="mt-4">
          <Col lg={{ span: 12 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'} py-4 px-3`}>
              <Title>User Total Completion Progress</Title>
              <Stat muted>
                ({
                  numberWithCommas(
                    playerCompletionProgress.finishedMaps +
                    playerCompletionProgress.finishedBonuses +
                    playerCompletionProgress.finishedStages
                  )
                }
                {' / '}
                {
                  numberWithCommas(
                    totals.totalMaps +
                    totals.totalBonuses +
                    totals.totalStages
                  )
                })
              </Stat>
              <div className="mt-3">
                <Stat >Overall Completion</Stat>
                <ProgressBar className="mb-3" animated variant="dark"
                  label={Math.round(
                    (
                      (
                        playerCompletionProgress.finishedMaps +
                        playerCompletionProgress.finishedBonuses +
                        playerCompletionProgress.finishedStages
                      )
                      /

                      (
                        totals.totalMaps +
                        totals.totalBonuses +
                        totals.totalStages
                      )) * 100) + '%'}
                  now={Math.round(
                    (
                      (
                        playerCompletionProgress.finishedMaps +
                        playerCompletionProgress.finishedBonuses +
                        playerCompletionProgress.finishedStages
                      )
                      /

                      (
                        totals.totalMaps +
                        totals.totalBonuses +
                        totals.totalStages
                      )) * 100)} key={1} />

                <Stat >Completed Maps</Stat>
                <ProgressBar className="mb-3" animated variant="success"
                  label={Math.round((playerCompletionProgress.finishedMaps / totals.totalMaps) * 100) + '%'}
                  now={Math.round((playerCompletionProgress.finishedMaps / totals.totalMaps) * 100)} key={2} />

                <Stat >Completed Bonuses</Stat>
                <ProgressBar className="mb-3" animated variant="info"
                  label={Math.round((playerCompletionProgress.finishedBonuses / totals.totalBonuses) * 100) + '%'}
                  now={Math.round((playerCompletionProgress.finishedBonuses / totals.totalBonuses) * 100)} key={3} />

                <Stat >Completed Stages</Stat>
                <ProgressBar className="mb-3" animated variant="warning"
                  label={Math.round((playerCompletionProgress.finishedStages / totals.totalStages) * 100) + '%'}
                  now={Math.round((playerCompletionProgress.finishedStages / totals.totalStages) * 100)} key={4} />
              </div>
            </Card>
          </Col>
        </Row>
        }
        <Row className="mt-4">
          <Col lg={{ span: 12 }}>
            <CardDeck>
              {mapCompletions}
            </CardDeck>
          </Col>
        </Row>
        {playerFinishedMaps && <Row className="mt-4">
          <Col lg={{ span: 12 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
              <MaterialTable
                title="Map Completions"
                data={playerFinishedMaps}
                columns={columnsMapCompletions}
                options={{
                  search: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50],
                }}
                icons={tableIcons}
                style={tableTheme}
              />
            </Card>
          </Col>
        </Row>
        }
        {playerFinishedBonuses && <Row className="mt-4">
          <Col lg={{ span: 12 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
              <MaterialTable
                title="Bonus Completions"
                data={playerFinishedBonuses}
                columns={columnsBonusCompletions}
                options={{
                  search: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50],
                }}
                icons={tableIcons}
                style={tableTheme}
              />
            </Card>
          </Col>
        </Row>
        }
        {playerFinishedStages && <Row className="mt-4">
          <Col lg={{ span: 12 }}>
            <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
              <MaterialTable
                title="Stage Completions"
                data={playerFinishedStages}
                columns={columnsStageCompletions}
                options={{
                  search: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50],
                }}
                icons={tableIcons}
                style={tableTheme}
              />
            </Card>
          </Col>
        </Row>
        }
      </Col>
    </Row>
    <Row className="mt-3">


      <Col lg={{ span: 8 }}>
        {/* <Card>

        </Card> */}
      </Col>
    </Row>
  </Container >
  ) : (
    <PlayerLoader theme={theme} />
  )
   


}

export default Player;