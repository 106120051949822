import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import {
  faUsers, faMap, faBold, faUserClock, faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function CardStatLoaded(props) {
  const { data } = props;
  const {
    totalPlayers,
    totalMaps,
    totalBonuses,
    totalCompletions,
    totalHoursPlayed,
  } = data;

  return (
    <CardDeck>
      <Card className="cardstat-bg-1 shadow">
        <Card.Body>
          <div className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faUsers} className="align-self-center mb-3" />
            <h5 className="text-muted mb-3">Ranked Players</h5>
            <span className="text-count">{numberWithCommas(totalPlayers)}</span>
          </div>
        </Card.Body>
      </Card>
      <Card className="cardstat-bg-2 shadow">
        <Card.Body>
          <div className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faMap} className="align-self-center mb-3" />
            <h5 className="text-muted mb-3">Maps</h5>
            <span className="text-count">{numberWithCommas(totalMaps)}</span>
          </div>
        </Card.Body>
      </Card>
      <Card className="cardstat-bg-3 shadow">
        <Card.Body>
          <div className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faBold} className="align-self-center mb-3" />
            <h5 className="text-muted mb-3">Bonuses</h5>
            <span className="text-count">{numberWithCommas(totalBonuses)}</span>
          </div>
        </Card.Body>
      </Card>
      <Card className="cardstat-bg-4 shadow">
        <Card.Body>
          <div className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faUserClock} className="align-self-center mb-3" />
            <h5 className="text-muted mb-3">Completions</h5>
            <span className="text-count">{numberWithCommas(totalCompletions)}</span>
          </div>
        </Card.Body>
      </Card>
      <Card className="cardstat-bg-5 shadow">
        <Card.Body>
          <div className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faClock} className="align-self-center mb-3" />
            <h5 className="text-muted mb-3">Hours Played</h5>
            <span className="text-count">{numberWithCommas(totalHoursPlayed)}</span>
          </div>
        </Card.Body>
      </Card>
    </CardDeck>

  );
}

export default CardStatLoaded;
