import React from 'react';
import {
  Card,
  Button,
} from 'react-bootstrap';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getter from '../../../helpers/getter';
import RecentlyAddedMapsLoaded from './RecentlyAddedMapsLoaded';
import RecentlyAddedMapsLoader from './RecentlyAddedMapsLoader';
import config from '../../../config/config';

function RecentlyAddedMaps(props) {
  const { data, loading } = getter(`${config.SSL}${config.HOST}/surfstats/new-maps`);
  const { theme } = props;

  return (
    <Card className={`${theme === 'light' ? 'shadow' : 'shadow'}`}>
      {
        loading ? ''
          : (
            <Card.Header className="card-header d-flex justify-content-between mb-0">
              <h3 className="mb-0 align-self-center">Recently Added Maps</h3>
              <Button href="/dashboard/maps" variant={theme}>
                {' '}
                <FontAwesomeIcon icon={faMap} className="mr-2" />
                View more
              </Button>
            </Card.Header>
          )
      }
      {
        loading
          ? <RecentlyAddedMapsLoader theme={theme} />
          : <RecentlyAddedMapsLoaded data={data} theme={theme} />
      }
    </Card>
  );
}

export default RecentlyAddedMaps;
