import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
} from 'react-bootstrap';
import axios from 'axios';
import config from '../../config/config';

function PayPal(props) {
  const {
    theme,
    steamId,
    setIsPaying,
  } = props;
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  const handleSubmit = () => {
    setIsPaying(false);
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => actions.order.create({
          purchase_units: [
            {
              description: 'VIP access to CSGO server',
              amount: {
                currency_code: 'EUR',
                value: 5,
              },
            },
          ],
        }),
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order);
          // make call to api to insert record in database for player
          axios.post(`${config.SSL}${config.HOST}/vip/add`, {
            steamId,
          }).then((res) => {
            console.log(res.data);
            setPaidFor(true);
          });
        },
        onError: (err) => {
          setError(err);
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  if (paidFor) {
    axios.post(`${config.SSL}${config.HOST}/discord/paymentwebhook/`, {
      steamId,
      ifVip: 1,
    }).catch((error) => {
      setError(error);
      console.error(error);
    });
    return (
      <Row className="mt-5 text-center">
        <Col lg={{ span: 12 }}>
          <Card>
            <Card.Header>
              Thank you for your support!
            </Card.Header>
            <Card.Body>

              <p className="text-center">
                We can confirm that you have succesfully purchased VIP access.
              </p>
              <div
                className="text-center mb-3"
              >
                <Button
                  style={{
                    padding: '1.5em',
                  }}
                  href="steam://connect/clarity.surf:27015"
                  className="w-25"
                  variant={theme}
                >
                  Connect to server

                </Button>
              </div>
              <div className="text-center">
                <Button
                  href="/"
                  style={{
                    padding: '1.5em',
                  }}
                  className="w-25"
                  variant={theme}
                >
                  Go to the Home Page

                </Button>
              </div>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="text-center">
      <Col className="mt-5" lg={{ span: 12 }}>
        <Card>
          <Card.Body>
            {' '}
            {
              error ? (
                <div>
                  Uh oh, an error occurred!
                  {error.message}
                </div>
              ) : ''
            }

            <div ref={paypalRef} className="text-center" />
            <p className="text-center">
              Payment processing is handled by PayPal. All we store is your steam profile.
              If you have any questions or concerns, please contact
              {' '}
              <strong>tslashd</strong>
              {' '}
              on Discord.
            </p>
            <Button variant={theme} onClick={() => handleSubmit()}>Cancel</Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default PayPal;
