import React from 'react';
import CardStatLoaded from './CardStatLoaded';
import CardStatLoader from './CardStatLoader';
import getter from '../../../helpers/getter';
import config from '../../../config/config';

function CardStat(props) {
  const { data, loading } = getter(`${config.SSL}${config.HOST}/surfstats/totals`);
  const { theme } = props;
  return (
    loading ? <CardStatLoader theme={theme} /> : <CardStatLoaded data={data} />
  );
}

export default CardStat;
